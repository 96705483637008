import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Typography, Card, CardContent } from '@mui/material';


// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: 'rgb(255, 231, 217)',
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

ChallengeWelcomeBox.propTypes = {
  displayName: PropTypes.string
};

export default function ChallengeWelcomeBox({projectname,date}) {

    const prjpercentage = {
        color: "rgb(122, 12, 46)",
        fontSize:"100px",
        position:"absoule",
        bottom:"-5px",
      };

      const wrapper = {
        display:"flex",
        flexDirection:"column",
        alignItems:"start",
        justifyContent:"end",
        height:"100%" 
      };

  return (
    <RootStyle>
      <CardContent style={wrapper}
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          color: 'grey.800'
        }}
      >
    
        <Typography gutterBottom variant="h4">
          {projectname}
         
        </Typography>

        <Typography gutterBottom variant="h4" style={prjpercentage}>
      {date}
        </Typography>

    </CardContent>
    <img src="/static/illustrations/welcome_woman.png" alt="welcome" />

      
    </RootStyle>
  );
}
