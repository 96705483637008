import { Link as RouterLink, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { useState, useEffect } from 'react';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppTrafficBySite,
  AppWidgetSummaryCat,
  ProjectWelcome,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { randomColor } from 'src/utils/colors';

import Loading from './Loading';
import { getProjectTypeBySubcity } from 'src/adapters/data/project';

export default function ProjectTypeBySubcity() {
  const theme = useTheme();
  //get id from url
  const { id } = useParams();

  const [projectTypes, setProjectTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  const getProjectTypes = async () => {
    const response = await getProjectTypeBySubcity(id);
    setProjectTypes(response.data.data);
    setLoading(false);
  };

  const formatGraphData = (projectGraph) => {
    let data = [];
    projectGraph.forEach((item) => {
      data.push({
        label: item.project_type_name,
        value: item.total_progress,
      });
    });

    return data;
  };

  useEffect(() => {
    getProjectTypes();
  }, []);

  return (
    <Page title="Project Dashboard">
      {loading ? (
        <Loading />
      ) : (
        <Container maxWidth="xl">
      
      
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <ProjectWelcome title={projectTypes[0].subcity_name} progress={projectTypes[0].total_progress} />
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppConversionRates
              title="Project Types"
              subheader="this year"
              chartData={formatGraphData(projectTypes)}
            />
          </Grid>

          {projectTypes.map((projectType) => {
            return (
              <Grid key={projectType.project_type_id} sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title={projectType.project_type_name}
                  total={projectType.total_progress}
                  icon={'ant-design:android-filled'}
                  link={'/dashboard/subcity/'+projectType.subcity_id+'/project-type' + '/' + projectType.project_type_id}
                />
              </Grid>
            );
          })}
          
        </Container>
      )}
    </Page>
  );
}
