import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// material
import { alpha, styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import MapSelector from 'src/components/MapSelector';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  Link,
  Button,
  LinearProgress,
  TableHead,
  TableContainer,
  Box,
  Tab,
  Card,
  Grid,
  Divider,
  Skeleton,
  Container,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummaryCat,
  ProjectWelcome,
  AppCurrentSubject,
  AppConversionRates,
  ChallengeWelcomeBox,
  ProjectDetailedBox,
} from '../sections/@dashboard/app';

import { getReportDetail } from 'src/adapters/data/report';

const LABEL = ['Pending', 'Cancel', 'Done'];

export default function ChallengeDetail() {
  const { id } = useParams();
  const [value, setValue] = useState('1');

  const [report, setReport] = useState({
    project: {},
  });
  const [showMap, setshowMap] = useState(false);

  const getReport = async () => {
    let report = await getReportDetail(id);
    console.log('report detail', report);
    setReport(report.data.data);
    setshowMap(true);
  };

  useEffect(() => {
    getReport();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const projectImage = {
    borderRadius: 10,
    width: '100%',
    height: '400px',
    objectFit: 'cover',
  };

  const projectDataTop = {
    padding: '60px',
  };

  const projectTop = {
    padding: '20px',
  };
  const projectimage = {
    margin: '8px',
  };
  return (
    <Page title="Project Details">
      <Container maxWidth={'lg'}>
        <ChallengeWelcomeBox projectname={report.project.name} date={report.date} />
        <br />
        <br />
        <>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Box style={projectimage}>
                <img src="/static/project.jpg" alt="project" style={projectImage} />
              </Box>
              <br />
              <Typography gutterBottom variant="h5">
                <span>Addis Ababa,</span>
                {showMap && <MapSelector initialLocation={{ lat: report.location_lat, long: report.location_long }} />}
              </Typography>
            </Grid>
          </Grid>
        </>

        <br />
        <br />

        <Card>
          <TabContext value={value}>
            <Box sx={{ px: 3, bgcolor: 'background.neutral' }}>
              <TabList onChange={handleChangeTab}>
                <Tab disableRipple value="1" label="Description" />
                <Tab disableRipple value="2" label="Audio Recording" />

                <Tab disableRipple value="3" label="Challenge" />
              </TabList>
            </Box>

            <Divider />

            <TabPanel value="1">
              <Box sx={{ p: 3 }}>
                <Typography gutterBottom variant="p">
                  {report.answer}
                </Typography>
              </Box>
            </TabPanel>

            <TabPanel value="2">
              <Box sx={{ p: 3 }}>
                <Typography gutterBottom variant="p">
                  {report.answer}
                </Typography>
              </Box>
            </TabPanel>

            <TabPanel value="3">
              <Box sx={{ p: 3 }}>
                <Typography gutterBottom variant="p">
                  {report.answer}
                </Typography>
              </Box>
            </TabPanel>
          </TabContext>
        </Card>

        <br />
        <br />
      </Container>
    </Page>
  );
}
