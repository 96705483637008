// material
import { Container,Typography } from '@mui/material';


// components
import Page from '../components/Page';
import UserNewForm from '../components/_dashboard/user/UserNewForm';

// ----------------------------------------------------------------------

export default function UserCreate() {

  return (
    <Page title="User: Create a new user">
      <Container maxWidth={'lg'}>
      <Typography variant="h4" gutterBottom>
              Create new user
            </Typography>

        <UserNewForm />
      </Container>
    </Page>
  );
}
