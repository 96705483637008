import * as React from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { createContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization'



// ----------------------------------------------------------------------
export const AppStateContext = createContext();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



export default function App() {
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setsnackbarMessage] = useState('test');
  const [snackbarSeverity, setSnackbacrSeverity] = useState('success');



  const triggerSnackBar = (message,serverity) => {
    setsnackbarMessage(message);
    setSnackbacrSeverity(serverity);
    setOpen(true);
  }
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  const appState = {
    user,
    setUser,
    isLoggedIn,
    setIsLoggedIn,
    triggerSnackBar,
  };

  return (
    <AppStateContext.Provider value={appState}>
      <ThemeProvider>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <Settings />
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
       
 <Snackbar bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical:"bottom", horizontal:"right" }}
      >
      <Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%' }}> 
    {snackbarMessage}
  </Alert>
 
  </Snackbar>
  </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
      </ThemeProvider>
    </AppStateContext.Provider>
  );
}

/* serverity="success,error,warning,info"  */
