import { Link as RouterLink, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { useState, useEffect } from 'react';
import { getProjectsByTypeID } from 'src/adapters/data/projectType';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
  AppTrafficBySite,
  AppWidgetSummaryCat,
  ProjectWelcome,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { randomColor } from 'src/utils/colors';

import Loading from './Loading';

export default function ProjectCategoryList() {
    const theme = useTheme();
   
  
  
  
    const [projectType, setProjectype] = useState({});
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);
  
    const getProjects = async () => {
    
  
       
    };
  
  
  return (
    <Page title="Project Cateogories Dashboard">
      {loading ? (
        <Loading />
      ) : (
        <Container maxWidth="xl">

<Grid container spacing={3}>
    
              <Grid sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title="project title"
                  total="62"
                  icon={'ant-design:android-filled'}
                  link=''
                />
              </Grid>


              <Grid sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title="project title"
                  total="62"
                  icon={'ant-design:android-filled'}
                  link=''
                />
              </Grid>


              <Grid sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title="project title"
                  total="62"
                  icon={'ant-design:android-filled'}
                  link=''
                />
              </Grid>


              <Grid sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title="project title"
                  total="62"
                  icon={'ant-design:android-filled'}
                  link=''
                />
              </Grid>


              <Grid sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title="project title"
                  total="62"
                  icon={'ant-design:android-filled'}
                  link=''
                />
              </Grid>


              <Grid sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title="project title"
                  total="62"
                  icon={'ant-design:android-filled'}
                  link=''
                />
              </Grid>


              <Grid sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title="project title"
                  total="62"
                  icon={'ant-design:android-filled'}
                  link=''
                />
              </Grid>


              <Grid sx={{ my: 5 }} item xs={12} sm={6} md={3}>
                <AppWidgetSummaryCat
                  color={randomColor()}
                  title="project title"
                  total="62"
                  icon={'ant-design:android-filled'}
                  link=''
                />
              </Grid>

              </Grid>

              
        
        
          
        </Container>
      )}
    </Page>
  );
}
