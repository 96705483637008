export const randomColor = () => {
  var colors = ['primary', 'secondary', 'error', 'warning', 'info', 'success'];
  //pick a random color from the list
  return colors[Math.floor(Math.random() * colors.length)];
};

export const convertedColor = (value) => {
  var colors = ['primary', 'secondary', 'error', 'warning', 'info', 'success'];

  //if a value is less than 25, eeturn error color
  if (value < 25) {
    return colors[2];
  }

  //if a value is more than 25 but less than 50 return warning color
  if (value > 25 && value <= 50) {
    return colors[3];
  }

  //if a value is more than 50 but less than 75 return info color
  if (value > 50 && value < 75) {
    return colors[4];
  }

  //if a value is more than 75 but less than 100 return success color
  if (value > 75 && value < 100) {
    return colors[5];
  }
};

export const convertedColorHext = (value) => {
  //if a value is less than 25, eeturn error color
  if (value <=   25) {
    return '#f44336';
  }

  //if a value is more than 25 but less than 50 return warning color
  if (value > 25 && value <= 50) {
    return '#ff9800';
  }

  //if a value is more than 50 but less than 75 return light green color
  if (value > 50 && value <= 75) {
    return '#03a9f4';
  }

  //if a value is more than 75 but less than 100 return success color
  if (value > 75 && value < 100) {
    return '#4caf50';
  }
};

export const convertColorText = (value) => {
  //if a value is less than 25, return red color
  if (value <= 25) {
    return 'red';
  }

  //if a value is more than 25 but less than 50 return orange color
  if (value > 25 && value <= 50) {
    return 'orange';
  }

  //if a value is more than 50 but less than 75 return light green color
  if (value > 50 && value <= 75) {
    return 'lightgreen';
  }

  //if a value is more than 75 but less than 100 return success color
  if (value > 75 && value < 100) {
    return 'green';
  }

}
