import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { login } from '../../../adapters/data/auth';
import { useContext } from "react";
import { AppStateContext } from "../../../App";



// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const appState = useContext(AppStateContext);

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: 'admin@green.com',
      password: 'password',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
    
      var response = await login(formik.values.email, formik.values.password);
      console.log("Am i even here?");
      console.log("response",response);
      if (response.status === 200) {
      
        appState.setUser(response.data.data);
        appState.setIsLoggedIn(true);
        appState.triggerSnackBar('Login Successful. Welcome '+response.data.data.name, 'success');

        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('user_name', response.data.data.name);
        localStorage.setItem('user_email', response.data.data.email);

        navigate('/dashboard', { replace: true });

      } else {
        isSubmitting = false;
       console.log("check",response.status.message);
        setError('Invalid email or password');
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            value="admin@green.com"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value="password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
          {error.length > 0 && (
            <Typography variant="caption" color="error.main">
              {error}
            </Typography>
          )}

          {/* <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
